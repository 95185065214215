import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { IMAGES } from '../constants/theme';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const portfolioBlog = [
    { image: IMAGES.galleryImg1, title: '54 Jervois ST, South Plympton Adelaide, SA', location: 'SA,Australia', subtitle: '', },
    { image: IMAGES.galleryImg2, changeStyle: 'mt-5', title: '31 Rickaby Street, Croydon Park, SA 5008,', location: 'SA,Australia', subtitle: '', },
    { image: IMAGES.galleryImg3, title: '360 Marion Road, North Plympton, SA 5037, ', location: 'SA,Australia', subtitle: '', },
    // { image: IMAGES.pic4, changeStyle: 'mt-5', title: 'Modern House Interior ', location: 'New York', subtitle: 'INTERIOR DESIGN', },
    // { image: IMAGES.pic5, title: 'Residantial Project,', location: 'Paris', subtitle: 'ARCHITECTURAL', },
    // { image: IMAGES.pic6, changeStyle: 'mt-5', title: 'Modern Family House,', location: 'Italy', subtitle: 'INTERIOR DESIGN', },
    // { image: IMAGES.pic4, title: 'Modern House Interior ', location: 'New York', subtitle: 'INTERIOR DESIGN', },
    // { image: IMAGES.pic5, changeStyle: 'mt-5', title: 'Residantial Project,', location: 'Paris', subtitle: 'ARCHITECTURAL', },
    // { image: IMAGES.pic6, title: 'Modern Family House,', location: 'Italy', subtitle: 'INTERIOR DESIGN', },
    // { image: IMAGES.pic4, changeStyle: 'mt-5', title: 'Modern House Interior ', location: 'New York', subtitle: 'INTERIOR DESIGN', },
    // { image: IMAGES.pic5, title: 'Residantial Project,', location: 'Paris', subtitle: 'ARCHITECTURAL', },
    // { image: IMAGES.pic6, changeStyle: 'mt-5', title: 'Modern Family House,', location: 'Italy', subtitle: 'INTERIOR DESIGN', },

];
const Projects = () => {
    const firstItem=portfolioBlog[0];
    const secondItem=portfolioBlog[1];
    const thirdItem=portfolioBlog[2];

    return (
        <>

            <section className="content-inner-2">
                <div className="container-fluid">
                    <Swiper className="swiper-container swiper-portfolio lightgallery aos-item"
                        slidesPerView={4}
                        spaceBetween={30}
                        breakpoints={{
                            1280: {
                                slidesPerView: 4,
                            },
                            991: {
                                slidesPerView: 3,
                            },
                            591: {
                                slidesPerView: 2,
                            },
                            300: {
                                slidesPerView: 1,
                            },
                        }}
                    >

                      
                            <SwiperSlide className="swiper-slide" key={firstItem}>
                                <motion.div className={`dz-box overlay style-1 ${firstItem.changeStyle}`} >                                    
                                    <Link to={firstItem.image}>
                                        <img alt="img1" src={firstItem.image} />
                                    </Link>
                                    <div className="dz-info">
                                        <span data-exthumbimage={firstItem.image} data-src={firstItem.image} className="view-btn lightimg" title="ARCHITECTURAL"></span>
                                        <h6 className="sub-title">{firstItem.subtitle}</h6>
                                        <h4 className="title m-b15"><Link to="/portfolio-details">{firstItem.title} <span>{firstItem.location}</span></Link></h4>
                                    </div>
                                </motion.div>
                            </SwiperSlide>

                            <SwiperSlide className="swiper-slide" key={secondItem}>
                                <motion.div className={`dz-box overlay style-1 ${secondItem.changeStyle}`} >                                    
                                    <Link to={secondItem.image}>
                                        <img alt="img1" src={secondItem.image} />
                                    </Link>
                                    <div className="dz-info">
                                        <span data-exthumbimage={firstItem.image} data-src={secondItem.image} className="view-btn lightimg" title="ARCHITECTURAL"></span>
                                        <h6 className="sub-title">{secondItem.subtitle}</h6>
                                        <h4 className="title m-b15"><Link to="/portfolio-details1">{secondItem.title} <span>{secondItem.location}</span></Link></h4>
                                    </div>
                                </motion.div>
                            </SwiperSlide>

                            <SwiperSlide className="swiper-slide" key={thirdItem}>
                                <motion.div className={`dz-box overlay style-1 ${thirdItem.changeStyle}`} >                                    
                                    <Link to={thirdItem.image}>
                                        <img alt="img1" src={thirdItem.image} />
                                    </Link>
                                    <div className="dz-info">
                                        <span data-exthumbimage={thirdItem.image} data-src={thirdItem.image} className="view-btn lightimg" title="ARCHITECTURAL"></span>
                                        <h6 className="sub-title">{thirdItem.subtitle}</h6>
                                        <h4 className="title m-b15"><Link to="/portfolio-details1">{thirdItem.title} <span>{secondItem.location}</span></Link></h4>
                                    </div>
                                </motion.div>
                            </SwiperSlide>
                     


                    </Swiper>
                </div >
                <div className="content-inner bg-secondary subscribe-area" style={{ backgroundImage: `url(images/background/bg2-1.png)`, backgroundPosition: 'center' }}>
                    <div className="container">
                        <div className="row subscribe-content">
                            <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                                <motion.div className="section-head style-1 mb-0"
                                    initial={{ opacity: 0, y: "200%" }}
                                    whileInView={{ opacity: 1, y: "0%" }}
                                    transition={{ duration: 0.8 }}
                                >
                                    <h6 className="sub-title text-primary">NEWSLETTER</h6>
                                    <h2 className="title text-white">Stay Updated With Us !</h2>
                                </motion.div>
                            </div>
                            <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                                <form className="dzSubscribe dz-subscription mt-3" action="#" method="post">
                                    <div className="dzSubscribeMsg dz-subscription-msg"></div>
                                    <motion.div className="input-group"
                                        initial={{ opacity: 0, y: "200%" }}
                                        whileInView={{ opacity: 1, y: "0%" }}
                                        transition={{ duration: 0.8, delay: 0.4 }}
                                    >
                                        <input name="dzEmail" required="required" className="form-control" placeholder="Enter Your Email Address..." type="email" />                                         
                                        <button name="submit" type="submit" className="btn btn-primary btn-rounded">Subscribe Now <i className="m-l10 fas fa-plus scale08"></i></button>
                                    </motion.div>
                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Projects