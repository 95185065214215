import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import LargeSidebar from '../components/LargeSidebar'
import { Link } from 'react-router-dom'

const testimonialBlog = [
    {img: IMAGES.blogGridPic2 },
    {img: IMAGES.blogGridPic1 },
]

const coments = [
    {name: "A", img: IMAGES.avatar },
    {changeStyle: "m-l40", name: "B", img: IMAGES.avatar1 },
    {name: "C", img: IMAGES.avatar2 },
]



const BlogDetail = () => {
    return (
        <>

            <div className="page-content bg-white">
                <CommanBanner mainTitle="Investing in Australia" parentTitle="Home" pageName="Blog Details" bgImage={IMAGES.bnr2} />
                <div className="content-inner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
                                <div className="dz-card blog-single style-1">
                                    <div className="dz-media">
                                        <img src={IMAGES.blogGridPic2} alt="" />
                                    </div>
                                    <div className="dz-info">
                                        <div className="dz-meta">
                                            <ul>
                                                <li>April 26, 2021</li>
                                                <li className="post-user">By <Link>John Doe</Link></li>
                                            </ul>
                                        </div>
                                        <h2 className="dz-title">Investing in Australia: How to overcome the land selection dilemma</h2>
                                        <div className="dz-post-text">
                                            <p>In Australia's booming property market, choosing an ideal location seems to be the start of all success. But in reality, both property developers and builders face various challenges in the process of selecting a site. Developers tend to analyse their choices mostly according to their own subjective experience. Such an approach, while simple and straightforward, brings with it many risks. They often choose areas they are familiar with or types of development they are familiar with, limiting the opportunities for more possibilities and innovation. Builders, on the other hand, have impeccable expertise in construction, but are less skilled in development processes and codes such as land subdivision and design. More critically, they are often unable to be precise in their cost estimates for the upfront land development portion of the project, which also brings a lot of uncertainty to the project. Today, I will talk specifically about how we solve the several pain points when meeting the difficulty of land selection.</p>
                                            <blockquote className="block-quote style-1">
                                                <p>Welcome to Cyberidge, a leading construction company specialising in innovative and sustainable building concepts. Our core philosophy is to create economic value while positively impacting society and the environment. We use sustainable materials, energy-efficient designs, and eco-friendly practices to ensure our projects support a greener future. </p>
                            
                                            </blockquote>
                                            <div className="alignleft w-50">
                                                <img alt="" src={IMAGES.blogGridPic2} className="w-100" />
                                            </div>
                                            <p>The pain points of the land selection puzzle:</p>
                                            <p>❌ Limitations of subjective experience</p>
                                            <p>❌ Stuck in familiar areas</p>
                                            <p>❌ Unfamiliarity with the development process</p>
                                            <p>❌ Difficulty in cost estimation</p>
                                            <h4>Our Solution:</h4>
                                            <p>✨ Property development is a delicate and complex task involving numerous factors, our property development data analysis is ready for precise site selection and can provide all-round data support.</p>
                                            <p>➡️ Objective data support: We provide scientific site selection recommendations based on objective data, taking into account geographic, market and social factors, from demographics and accessibility to economic indicators.</p>
                                            <p>➡️ Cost Estimation Tools: We provide advanced cost estimation tools based on historical data and actual cases to help estimate development costs more accurately and avoid investment risks.</p>
                        

                                            <p>➡️ Multi-dimensional Data Analysis: We have multi-dimensional data collection and processing capabilities, presenting a comprehensive site selection strategy by comprehensively.</p>
                                            <p> ➡️ Automation Tools: We provide tools to automate access to subdivision policies, calculate ROI, and more, saving time and cost.</p>
                                            <p> Whether you're a property developer looking for growth opportunities or a builder looking for a return on investment, our property analytics will reliably help you get a head start on the Australian property market, enabling smarter, more successful investments through data-driven decision-making.</p>
                                        </div>
                                        <div className="post-footer">
                                            <div className="post-tags">
                                                <strong>Tags:</strong>
                                                <Link rel="tag">Construction</Link>
                                            </div>
                                            {/* <div className="dz-share-post">
                                                <h5 className="title">Share :</h5>
                                                <ul className="dz-social-icon">
                                                    <li><Link target="_blank"><i className="fab fa-facebook-f"></i></Link></li>
                                                    <li><Link target="_blank"><i className="fab fa-twitter"></i></Link></li>
                                                    <li><Link target="_blank"><i className="fab fa-linkedin"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="author-box blog-user m-b60">
                                    <div className="author-profile-info">
                                        <div className="author-profile-pic">
                                            <img src={IMAGES.testimonial} alt="Profile Pic" />
                                        </div>
                                        <div className="author-profile-content">
                                            <h6>John Doe</h6>
                                            <p>We woke reasonably late following the feast and free flowing wine the night before. After gathering ourselves and our packs, we headed down to our home.</p>
                                            <ul className="list-inline m-b0">
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fas fa-globe"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fab fa-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fab fa-youtube"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
{/* 
                                <div className="row extra-blog style-1">
                                    <div className="col-md-12">
                                        <div className="widget-title">
                                            <h4 className="title"> Related Blogs</h4>
                                            <div className="dz-separator style-1 text-primary mb-0"></div>
                                        </div>
                                    </div>
                                    {
                                        testimonialBlog.map((i, index) => (
                                            <div className="col-md-6" key={index}>
                                                <div className="dz-card blog-grid style-1 aos-item">
                                                    <div className="dz-media">
                                                        <Link to="/blog-details"><img src={i.img} alt="" /></Link>
                                                    </div>
                                                    <div className="dz-info">
                                                        <div className="dz-meta">
                                                            <ul>
                                                                <li className="post-date">
                                                                    <strong>26</strong>
                                                                    <span>Apr</span>
                                                                </li>
                                                                <li className="post-user">By <Link>Willam Jiang</Link></li>
                                                            </ul>
                                                        </div>
                                                        <h5 className="dz-title"><Link to="/blog-details">Modern Family House.</Link></h5>
                                                        <div className="read-more">
                                                            <Link className="btn btn-primary btn-rounded btn-sm hover-icon"><span>Read More </span><i className="fas fa-arrow-right"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div> */}
{/* 
                                <div className="clear" id="comment-list">
                                    <div className="comments-area style-1" id="comments">
                                        <div className="widget-title">
                                            <h4 className="title">8 Comments</h4>
                                            <div className="dz-separator style-1 text-primary mb-0"></div>
                                        </div>
                                        <div className="clearfix">
                                            <ol className="comment-list">
                                                {
                                                    coments.map((i, index) => (
                                                        <li className={`comment ${i.changeStyle}`} key={index}>
                                                            <div className="comment-body">
                                                                <div className="comment-author vcard">
                                                                    <img className="avatar photo" src={i.img} alt="" />
                                                                    <cite className="fn">John Doe</cite>
                                                                </div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                                <div className="reply">
                                                                    <Link className="comment-reply-link">
                                                                        <i className="fa fa-reply"></i>Reply</Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }

                                            </ol>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="comment-respond style-1" id="respond">
                                    <div className="widget-title">
                                        <h4 className="title">Leave Your Comment</h4>
                                        <div className="dz-separator style-1 text-primary mb-0"></div>
                                        <Link style={{ display: 'none' }} id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link>
                                    </div>
                                    <form className="comment-form" id="commentform" method="post">
                                        <p className="comment-form-author">
                                            <label htmlFor="author">Name <span className="required">*</span></label>
                                            <input type="text" name="Author" placeholder="Author" id="author" />
                                        </p>
                                        <p className="comment-form-email">
                                            <label htmlFor="email">Email <span className="required">*</span></label>
                                            <input type="text" placeholder="Email" name="email" id="email" />
                                        </p>
                                        <p className="comment-form-author w-100">
                                            <label htmlFor="author">Subtitale <span className="required">*</span></label>
                                            <input type="text" name="Author" placeholder="Subtitale" id="subtitale" />
                                        </p>
                                        <p className="comment-form-comment">
                                            <label htmlFor="comment">Comment</label>
                                            <textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                        </p>
                                        <p className="form-submit">
                                            <button type="submit" className="btn shadow-primary btn-primary btn-icon btn-rounded" id="submit">POST NOW
                                                <i className="fas fa-caret-right ms-1"></i>
                                            </button>
                                        </p>
                                    </form>

                                    
                                </div>
                            </div>
                            {/* <LargeSidebar /> */}
                        </div>
                    </div>
                </div>
            </div>

        </>)
}

export default BlogDetail