import React from "react";
import CommanBanner from "../elements/CommanBanner";
import { IMAGES } from "../constants/theme";
import Working from "../components/Working";
import Projects from "../components/Projects";
import Testimonial1 from "../components/Testimonial1";
import Testimonial2 from "../components/Testimonial2";
import Faq from "../components/Faq";
import { motion } from "framer-motion";
import ComingSoon from "./ComingSoon";
import Error404 from "./Error404";
import Brand from "../components/Brand";

const workingProcess = [
  { num: "01", heading: "Receive Project" },
  { num: "02", heading: "Planning Work" },
  { num: "03", heading: "Creative Design" },
  { num: "04", heading: "Start Building" },
];

const AboutUs = () => {
  const firstItem = workingProcess[0];
  const secondItem = workingProcess[1];
  const thirdItem = workingProcess[2];
  const forthItem = workingProcess[3];

  return (
    <>
      <div className="page-content bg-white">
        <CommanBanner
          mainTitle="About Us"
          parentTitle="Home"
          pageName="About Us"
          bgImage={IMAGES.bnr9}
        />
        <section
          className="section-full content-inner about-bx2"
          style={{
            backgroundImage: `url(${IMAGES.background2})`,
            backgroundPosition: "right bottom",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <div className="container">
            <ComingSoon />
          </div> */}
        </section>

        <div className="section-head style-1 text-center">
          <h6 className="sub-title text-primary">CYBERIDGE CONSTRUCTION</h6>
        </div>

        <section
          className="about_section about_section2 layout_padding-bottom"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="heading_container"
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <h2
              className="dz-title ml2"
              style={{
                fontFamily: "Arial Black, Gadget, sans-serif",
                fontSize: "46px",
                fontWeight: "bold",
                lineHeight: "1.2",
              }}
            >
              <span>Leading the Way in </span>
              <br />
              <span>Sustainable Construction</span>
            </h2>
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              className="row"
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingRight: "20px" }}
              >
                <div className="detail-box">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                    Welcome to Cyberidge, a leading construction company
                    specialising in innovative and sustainable building
                    concepts. Our core philosophy is to create economic value
                    while positively impacting society and the environment. We
                    use sustainable materials, energy-efficient designs, and
                    eco-friendly practices to ensure our projects support a
                    greener future. Our team, composed of experienced
                    architects, engineers, and investment professionals, is
                    committed to delivering projects that not only meet but
                    exceed sustainability standards. At Cyberidge, we believe in
                    building a better, greener tomorrow through responsible and
                    innovative construction practices.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                style={{ flex: 1, paddingLeft: "20px" }}
              >
                <div className="img-box" style={{ textAlign: "center" }}>
                  <img
                    src={IMAGES.about001}
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "600px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Error404/>
                    </div>
                </section> */}
        <section className="content-inner-2">
          <div className="container">
            <div className="section-head style-1 text-center">
              <h6 className="sub-title text-primary">WORK PROCESS</h6>
              <h2 className="title">How Our Work Process</h2>
            </div>
            <div className="row">
              {/* First Item */}
              <motion.div
                className="col-lg-3 col-sm-6"
                key={firstItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{firstItem.num}</div>
                  <h4 className="title m-b15">
                    Project Consultation: Discuss sustainable requirements,
                    budget, and expectations.
                  </h4>
                  <p className="m-b0">
                    Clients liaise with the architectural firm to discuss
                    requirements, budget, and expectations.
                  </p>
                </div>
              </motion.div>

              {/* Second Item */}
              <motion.div
                className="col-lg-3 col-sm-6"
                key={secondItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{secondItem.num}</div>
                  <h4 className="title m-b15">
                    Project Evaluation and Selection: Evaluate potential
                    projects with a focus on sustainable and green building
                    opportunities.
                  </h4>
                  <p className="m-b0">
                    Potential construction projects are evaluated, including
                    location, development potential, expected returns, etc.
                  </p>
                </div>
              </motion.div>

              {/* Third Item */}
              <motion.div
                className="col-lg-3 col-sm-6"
                key={thirdItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{thirdItem.num}</div>
                  <h4 className="title m-b15">
                    Financial Planning and Fund Raising: Plan and secure funds
                    for eco-friendly and energy-efficient projects.
                  </h4>
                  <p className="m-b0">
                    Determine the financial needs of the investment project.
                  </p>
                </div>
              </motion.div>

              {/* Forth Item */}

              <motion.div
                className="col-lg-3 col-sm-6"
                key={forthItem.num}
                initial={{ opacity: 0, y: "100%" }}
                whileInView={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <div className="work-process shadow text-center m-b30">
                  <div className="number">{forthItem.num}</div>
                  <h4 className="title m-b15">
                    Return on Investment and Analysis: Analyse project returns,
                    ensuring they align with our green building goals and
                    sustainability metrics.
                  </h4>
                  <p className="m-b0">
                    Adjust future investment strategies based on project
                    results.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>
        {/* <section className="content-inner-2">
                    <Projects />
                </section> */}
        <section
          className="content-inner-2"
          style={{
            backgroundImage: `url(${IMAGES.bg2})`,
            backgroundPosition: "right bottom",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <Testimonial1 /> */}
        </section>
        <section className="content-inner-2">{/* <Testimonial2 /> */}</section>
        <section
          className="section-full content-inner overflow-hidden"
          style={{
            backgroundImage: `url(${IMAGES.bg1})`,
            backgroundPosition: "left top",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Faq />
        </section>
{/* 
        <section class="team-bg">
          <div class="container">
            <div class="row">
              <div className="section-head style-1 text-center">
                <h6 className="sub-title text-primary">Team</h6>
                <h2 className="title">Our Team</h2>
              </div>
            </div>
          </div>
        </section>

        <section
          className="team-bg"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center"></div>
            </div>
          </div>
        </section>
        <section className="team-section-1">
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="team-slider"
                  style={{ display: "flex", overflowX: "auto", gap: "20px" }}
                >
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>William Jiang</h5>
                      <p>Director</p>
                    </div>
                  </div>
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>Peter Xiao</h5>
                      <p>Civil Engineer</p>
                    </div>
                  </div>
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>Viv Shi</h5>
                      <p>Strategy Manager</p>
                    </div>
                  </div>
                  <div
                    className="team-item-01"
                    style={{
                      flex: "0 0 auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="tm-thumb"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={IMAGES.about01}
                        alt=""
                        style={{
                          width: "325px",
                          height: "400px",
                          borderRadius: "15px", // Add this line
                        }}
                      />
                    </div>
                    <div className="tm-content" style={{ textAlign: "center" }}>
                      <h5>Ava Li</h5>
                      <p>Civil Engineer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner-1">
          <div className="container">
            <div className="section-head style-1 text-center">
              <h6 className="sub-title text-primary">Brands</h6>
              <h2 className="title">Our Top Partners</h2>
            </div>
            <Brand />
          </div>
        </section> */}
      </div>
    </>
  );
};
export default AboutUs;
