import React from 'react'
import { IMAGES } from '../constants/theme'
import CommanBanner from '../elements/CommanBanner'
import { Link } from 'react-router-dom'

const Error404 = () => {
    return (
        <div className="page-content bg-white">
        <div className="container">
            <div className="content-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    
          
                <div className="text-content" style={{ flex: 1, paddingRight: '20px' }}>
                    {/* <div className="error-page text-center">
                        <h2 className="head">Our Promise</h2>
                    </div> */}
    
                    <div className="additional-text">
                        <p><strong style={{ fontSize: '24px', fontWeight: 'bold' }}>Our Strategies</strong> focus on Sustainable Innovation: A commitment to advancing green building methods, investing in new technologies, and implementing efficient project management processes. This ensures we lead the industry in creating environmentally responsible projects that meet market needs while enhancing quality and efficiency.</p>
                        <p><strong style={{ fontSize: '24px', fontWeight: 'bold' }}>Our Promise</strong> is to a Sustainable Future: We commit to long-term growth and value creation through eco-friendly practices. Our projects aim to reduce environmental impact while delivering economic returns and positive social contributions. </p>
                        <p><strong style={{ fontSize: '24px', fontWeight: 'bold' }}>Our Potential</strong> lies in Sustainable Growth: We remain flexible and adaptable, leveraging market trends and customer needs to optimise internal processes. Our focus on sustainability ensures continued progress, new opportunities, and greater value for customers and investors.</p>

                    </div>


                </div>
    
           
                <div className="slider-box" style={{ flex: 1 }}>
                    <img src={IMAGES.bg8} alt="" style={{ width: '100%', height: 'auto' }} />
                </div>
    
            </div>
        </div>
    </div>
    
    )
}

export default Error404
