import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { IMAGES } from '../constants/theme'
import { motion } from 'framer-motion'


const accordian = [
    { id: 1, key: 0, title: " Financial returns and risk assessment of the project ?", data: "We have carefully assessed the financial returns and risks of the project. We forecast that the project will provide a solid annualised rate of return based on market research and financial analysis, while our strategy aims to minimise risk through investment diversification and rigorous market monitoring. We always follow legal and compliance standards and are committed to managing and mitigating any potential risks while maintaining returns." },
    { id: 2, key: 1, title: " Sed vehicula ullamcorper odio ac pellentesque ?", data: "We assess their future growth prospects and strategic plans, analysing current and future market trends by analysing market trends and demand analysis. And technological innovation and adaptability, we will enhance competitiveness by understanding how to adopt new technologies such as green building materials, automation and digital tools to reduce costs." },
    { id: 3, key: 2, title: " Sed vehicula ullamcorper odio ac pellentesque ?", data: " Our position in the market and our competitive advantage, expertise and range of services means that we offer a wider range of services than other companies might, including design, construction and maintenance. We are also technologically innovative and adaptable, using advanced construction techniques, automated tools or environmentally friendly building methods. Technological leadership can be an important competitive advantage for us." },
    { id: 4, key: 3, title: " What sustainable building practices do we use ?", data: " We prioritise eco-friendly building techniques, including the use of sustainable materials, energy-efficient designs, and water conservation systems." },
    { id: 5, key: 4, title: " How do our green projects benefit the environment ?", data: " Our green projects aim to reduce carbon footprints, promote energy efficiency, and enhance overall environmental sustainability." }
]




const Faq = () => {


    const firstItem=accordian[0];
    const secondItem=accordian[1];
    const thirdItem=accordian[2];
    const fourthItem=accordian[3];
    const fifthItem=accordian[4];

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <motion.div className="col-lg-6 m-b30 aos-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="300"
                        initial={{ opacity: 0, x: "-100%" }}
                        whileInView={{ opacity: 1, x: "0%" }}
                        transition={{ duration: 1 }}
                    >
                        <div className="twentytwenty-img-area">
                            <div className="twentytwenty-container">
                                <img src={IMAGES.pic2_2} alt="" />
                            </div>
                        </div>
                    </motion.div>
                    <div className="col-lg-6 aos-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600">
                        <div className="section-head style-1">
                            <h6 className="sub-title text-primary">FAQ</h6>
                            <h2 className="title">Get Every Answer From Here</h2>
                        </div>
                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={firstItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${firstItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                Financial returns and risk assessment of the project ?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0">{firstItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={secondItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${secondItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                What are our future growth prospects and strategic plans?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0">{secondItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={thirdItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${thirdItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                Where we are in the market and what our competitive advantages are？
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0">{thirdItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>


                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={fourthItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${fourthItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                            What sustainable building practices do we use?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0">{fourthItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={fifthItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${fifthItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                            How do our green projects benefit the environment?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0">{fifthItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq