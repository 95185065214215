import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const card = [
    { id: 1, delay: 0.2, icon: 'flaticon-blueprint-1' },
    { id: 2, delay: 0.4, icon: 'flaticon-crane' },
    { id: 3, delay: 0.6, icon: 'flaticon-interior-design-1' },
    { id: 4, delay: 0.8, icon: 'flaticon-furniture' },
    { id: 5, delay: 1, icon: 'flaticon-home' },
    { id: 6, delay: 1.2, icon: 'flaticon-support' },

]

const PopularService = () => {
    const [addActive, setAddActive] = useState(1);
    const firstItem = card[0];
    const secondItem = card[1];
    const thirdItem = card[2];
    const fourthItem = card[3];

    return (
        <>
            <div className="container">
                <div className="section-head style-1 text-center">
                    <h2 className="sub-title text-primary">Our Featured Services</h2>
                    {/* <h2 className="title">Our Featured Services</h2> */}
                </div>
                <div className="row">

                    <motion.div key={firstItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: firstItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === firstItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(firstItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={firstItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">Property development</Link></h4>
                                <p className="m-b30">The data analytics team optimizes construction costs and project value using expert recommendations and real-time market data.</p>
                                <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div key={secondItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: secondItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === secondItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(secondItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={secondItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">Sustainable Residential Construction</Link></h4>
                                <p className="m-b30">Residential construction combines sustainability, functionality, safety, and technology for efficient living spaces.</p>
                                <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>

                    
                    <motion.div key={thirdItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: thirdItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === thirdItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(thirdItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={thirdItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">Professional Team</Link></h4>
                                <p className="m-b30">Our team uses Revit and Vertex to ensure seamless collaboration, reduce issues, and deliver efficient, high-quality residential construction.</p>
                                <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>


                    <motion.div key={fourthItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: fourthItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === fourthItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(fourthItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={fourthItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">One-stop service</Link></h4>
                                <p className="m-b30">
                                The one-stop service model integrates design, construction, and management, ensuring efficient timelines, cost control, and high-quality results from start to finish.</p>
                                <Link to="/services" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>


                    
                </div>
            </div>
        </>
    );
}



export default PopularService;
