import React from 'react'
import { IMAGES } from '../constants/theme'
import CommanBanner from '../elements/CommanBanner'
import { Link } from 'react-router-dom'

const Vision = () => {
    return (
                    <div className="page-content bg-white" style={{ position: 'relative', height: '50vh' }}>
             
                    <img src={IMAGES.bg9} alt="" style={{ width: '100%', position: 'absolute', top: 0, left: 0 }} />
                
                   
                    <div style={{ position: 'absolute', top: '10%', left: '15%', width: '70%', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', textAlign: 'left' }}>
                        <div className="error-page text-center">
                            <h2 className="head">Future Vision</h2>
                        </div>
                        <p>Our Future Vision is a Green Future: We aim to create development models that integrate innovation and sustainability, driving economic growth and social well-being. By investing in smart cities and eco-friendly projects, we minimise environmental impacts and create a more prosperous and liveable urban environment for future generations. </p>
                    </div>
                </div>
    
    )
}

export default Vision





