import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import LargeSidebar from '../components/LargeSidebar'
import { Link } from 'react-router-dom'



const ServiceDetail3 = () => {
    const centerStyle = {
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '100vh', 
    };
    return (
        <>
         <div style={centerStyle}>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="Commercial construction" parentTitle="Home" pageName="About Us" bgImage={IMAGES.bnr2} />
                <div className="content-inner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
                                <div className="dz-card blog-single style-1">
                                    <div className="dz-media">
                                        <img src={IMAGES.blogGridPic2} alt="" />
                                    </div>
                                    <div className="dz-info">
                                  
                                        <h2 className="dz-title">Commercial construction</h2>
                                        <div className="dz-post-text">

                                            <p>The specialized data analytics team for property development possesses a proprietary database capable of conducting detailed analyses and estimations of construction costs from a professional perspective. Through in-depth analysis, the team provides expert recommendations based on real-time market data and cost-benefit analysis. This helps clients optimize their budgets and enhance the overall value of their projects.</p>
                                            <p>
                                            Building Information Modeling (BIM) is a revolutionary approach to construction project management, utilizing intelligent 3D modeling to facilitate comprehensive planning, design, and construction processes. By integrating detailed information on building components and systems, BIM enables architects, engineers, and construction professionals to collaborate seamlessly. This technology enhances accuracy, reduces errors, and optimizes efficiency by providing a virtual representation of the physical and functional characteristics of a building. BIM's ability to simulate real-world conditions and foresee potential issues before they arise ensures smoother project execution and superior results. It transforms traditional workflows by fostering innovation and improving project outcomes through enhanced visualization, coordination, and data management.</p>
                                            <blockquote className="block-quote style-1">
                                                <p>Welcome to Cyberidge, a leading construction company specialising in innovative and sustainable building concepts. Our core philosophy is to create economic value while positively impacting society and the environment. We use sustainable materials, energy-efficient designs, and eco-friendly practices to ensure our projects support a greener future. </p>
                            
                                            </blockquote>
                                            <div className="alignleft w-50">
                                                <img alt="" src={IMAGES.blogGridPic2} className="w-100" />
                                            </div>
                                            <p>Through expert market analysis and strategy, we are committed to delivering stable and attractive long-term returns to our investors. We emphasise transparency and open communication to ensure that our clients are always informed of the progress of their investments and market developments. At the same time, we also focus on risk management, through comprehensive risk assessment and strategy implementation, to reduce investment risks and protect our clients' assets. We provide ongoing support and professional guidance from project planning to completion, and even beyond, to ensure that every investment is a smart and trustworthy choice.</p>
                                            <h4>Our Advantages</h4>
                                            <p>High Return Potential:  </p>
                                            <p> Commercial real estate typically offers high rental income and long-term capital appreciation, generating significant returns for investors.</p>
                                            <p>Market Diversity:</p>
                                            <p>We will covers a wide range of types, such as retail space, office buildings, hotels or warehouses, providing a diverse range of investment options to suit different market needs.</p>
                                            <p>Stable cash flow:</p>
                                            <p>We will provide a stable rental income through long-term leases, bringing continuous cash flow to investors.</p>
                                            <p>Market Adaptability:</p>
                                            <p>We are only responsible for the purchase and sale of properties, but also provide property management services including repairs, leasing and day-to-day management, thus reducing the burden on the investor.</p>
                                            <p>Professional management:  </p>
                                            <p>We will provide comprehensive property management services, including tenant management, property maintenance and marketing, reducing the management burden on investors.</p>

                                        </div>
                                
                                    </div>
                                </div>
                      
                          
                      


                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </>)
}

export default ServiceDetail3