import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import PopularService from '../components/PopularService'
import VideoCounter from '../components/VideoCounter'
import Faq from '../components/Faq'
import Testimonial2 from '../components/Testimonial2'
import Testimonial1 from '../components/Testimonial1'
import Progress from '../components/Progress'
import Brand from '../components/Brand'
import Error404 from './Error404'
import Vision from './Vision'




const Services = () => {
    return (
        <>
            <div className="page-content bg-white">
            <CommanBanner mainTitle="Our Services" parentTitle="Home" pageName="Our Services" bgImage={IMAGES.bnr9} />
            
            {/* <section className="dz-content-bx style-3">
                <VideoCounter />
            </section> */}
            {/* <section className="content-inner-2" style={{ backgroundImage: `url(${IMAGES.bg2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <Testimonial1 />
            </section> */}
            <section className="content-inner-2">
                <Testimonial2 />
            </section>
            {/* <section className="section-full content-inner overflow-hidden" style={{ backgroundImage: `url(${IMAGES.bg1})`, backgroundPosition: 'left top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <Faq />
            </section> */}
            {/* <section className="section-full dz-content-bx style-2 text-white" >
                <Progress />
            </section> */}

            
<section class="about_section about_section1 layout_padding">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="img-box"style={{ flex: 1, paddingRight: '20px' }}>
          <img src={IMAGES.services01} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              Property development
              </h2>
            </div>
            <p>
            A specialized data analytics team significantly enhances property development by using
             a proprietary database for comprehensive analyses and precise cost estimations.
              By examining real-time market data and conducting detailed cost-benefit analyses, the team provides 
              expert recommendations that help clients optimize their budgets and enhance project value and 
              profitability. This approach allows for efficient resource allocation and early identification of 
              risks and opportunities, ensuring high-quality outcomes that meet market demands and standards. 
              The team's ability to forecast future trends and adjust strategies accordingly adds significant 
              value, making the entire development process more streamlined and successful.
            </p>
            <a href="">
            
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> 


  <section class="about_section about_section2 layout_padding-bottom">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              Sustainable Residential Construction
              </h2>
            </div>
            <p>
            Residential construction involves designing, building, and maintaining structures for habitation, 
            including single-family homes, multi-family residences, and mixed-use buildings. This field emphasizes 
            sustainable design using eco-friendly materials and energy-efficient technologies, combining functionality
             with aesthetics to meet residents' needs and create attractive spaces. Ensuring safety and compliance with
              local building codes is crucial, as is integrating smart home systems and modern technologies to enhance 
              convenience and security. Successful residential construction requires innovation and a focus on the long-term 
              needs of the community and its residents.
            </p>
            <a href="">
            </a>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="img-box">
          <img src={IMAGES.services02} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
      </div>
    </div>
  </section>


    <section class="about_section about_section1 layout_padding">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="img-box">
          <img src={IMAGES.services03} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
                Professional Team
              </h2>
            </div>
            <p>
            Our professional team excels in residential construction, leveraging their extensive expertise and 
            advanced tools. Using Building Information Modeling (BIM), architects work with Revit, and structural
             engineers use Vertex to create a coordinated 3D model. This integrated approach ensures seamless collaboration
              across all trades, reducing on-site issues, and accelerating construction. Our team's dedication to precision 
              and efficiency guarantees high-quality results, making us a trusted partner in delivering exceptional residential projects.
            </p>
            <a href="">
            
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> 


  <section class="about_section about_section2 layout_padding-bottom">
    <div class="container  ">
      <div class="row">
        <div class="col-lg-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
              One-stop service
              </h2>
            </div>
            <p>
            The design and build one-stop service model integrates design, construction, 
            and project management into a single service provider. This approach offers clients
             comprehensive services from initial concept design through to completion, simplifying communication,
              speeding up project timelines, and effectively controlling costs. This mode enables investors to conveniently
               fulfill their construction needs while ensuring project quality and efficiency.
            </p>
            <a href="">
            </a>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="img-box">
          <img src={IMAGES.services04} alt="" style={{ width: '500px', height: '300px', marginBottom: '120px' }} />
          </div>
        </div>
      </div>
    </div>
  </section>`











                {/* <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Error404/>
                    </div>
                </section> */}

                <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Vision/>
                    </div>
                </section>

            {/* <section className="content-inner-1">
                <div className="container">
                    <div className="section-head style-1 text-center">
                        <h6 className="sub-title text-primary">Brands</h6>
                        <h2 className="title">Our Top Partners</h2>
                    </div>
                    <Brand />
                </div>
            </section> */}
            </div>
        </>
    )
}

export default Services